
namespace $.$$ {

	export class $koplenov_blog extends $.$koplenov_blog {

		@$mol_mem
		title( title: string ) {
			return title + super.title()
		}

		@$mol_mem
		post_title( id: Post ) {
			return id.title
		}

		@$mol_mem
		post_url( id: Post ) {
			return '#id=' + id.path
		}

		@$mol_mem
		post_content( id: any ) {
			return this.$.$mol_fetch.text( id.path || $mol_state_arg.value( 'id' )?.split( '#id=' ).at( -1 ) )
		}

		content() {
			const currentPage = $mol_state_arg.value( 'id' )
			if( currentPage == null ) {
				this.title( 'Главная страница' )
				this.seo_description_content( undefined )

				// simple search
				let pages = this.pages()
				let search = this.search().query()
				return pages.filter( function( ele ) {
					return ele.title().toLowerCase().includes( search.toLowerCase() )
				} )
			}
			else {
				this.search().query( "" )
				let page = this.pageByPath( currentPage )
				this.title( this.post_title( page ) )
				this.seo_description_content( this.post_content( page ) )
				return [
					this.PostView( page ),
				] as readonly any[]
			}
		}

		pages() {
			const pages = []
			for( const page of this.gists() ) {
				pages.push( this.Post( page ) )
			}
			return pages
		}

		@$mol_mem
		gists() {
			const posts: Post[] = []
			const sitemap = this.$.$mol_fetch.xml( 'data.xml' )

			function signify( item: any, defauld: string ) {
				if( item && item.textContent ) {
					const content = item.textContent.split( '#id=' ) as string[]
					return content[ content.length - 1 ]
				}
				return defauld
			}

			for( const iterator of sitemap.documentElement.children ) {
				posts.push(
					{
						title: iterator.children.item( 2 )!.textContent || 'blank title',
						//path: iterator.children.item( 0 )!.textContent?.split( '#id=' ).at( -1 ) || 'blanl path'
						path: signify( iterator.children.item( 0 ), 'blanl path' )
					}
				)
			}
			return posts
		}

		pageByPath( path: string ) {
			const pages = this.gists()
			for( const page of pages ) {
				if( page.path == path )
					return page
			}
			return 'not found'
		}
	}

	interface Post {
		title: string
		path: string
	}
}
