namespace $ {
	export class $mol_seo extends $mol_view {
		
		/**
		 * ```tree
		 * name \
		 * ```
		 */
		name() {
			return ""
		}
		
		/**
		 * ```tree
		 * content \
		 * ```
		 */
		content() {
			return ""
		}
		
		/**
		 * ```tree
		 * dom_name \meta
		 * ```
		 */
		dom_name() {
			return "meta"
		}
		
		/**
		 * ```tree
		 * attr *
		 * 	name <= name
		 * 	content <= content
		 * ```
		 */
		attr() {
			return {
				name: this.name(),
				content: this.content()
			}
		}
	}
	
	export class $mol_seo_description extends $mol_seo {
		
		/**
		 * ```tree
		 * name \description
		 * ```
		 */
		name() {
			return "description"
		}
	}
	
	export class $mol_seo_robots extends $mol_seo {
		
		/**
		 * ```tree
		 * name \robots
		 * ```
		 */
		name() {
			return "robots"
		}
	}
	
	export class $mol_seo_canonical extends $mol_view {
		
		/**
		 * ```tree
		 * content \
		 * ```
		 */
		content() {
			return ""
		}
		
		/**
		 * ```tree
		 * dom_name \link
		 * ```
		 */
		dom_name() {
			return "link"
		}
		
		/**
		 * ```tree
		 * attr *
		 * 	rel \canonical
		 * 	href <= content
		 * ```
		 */
		attr() {
			return {
				rel: "canonical",
				href: this.content()
			}
		}
	}
	
}

