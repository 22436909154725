namespace $ {
	export class $koplenov_blog extends $mol_page {
		
		/**
		 * ```tree
		 * plugins / <= Themme
		 * ```
		 */
		plugins() {
			return [
				this.Themme()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * title* \ | koplenov blog
		 * ```
		 */
		title(id: any) {
			return " | koplenov blog"
		}
		
		/**
		 * ```tree
		 * Head <= head_title
		 * ```
		 */
		Head() {
			return this.head_title()
		}
		
		/**
		 * ```tree
		 * body <= content
		 * ```
		 */
		body() {
			return this.content()
		}
		
		/**
		 * ```tree
		 * Post*0 $mol_link
		 * 	uri <= post_url*
		 * 	title <= post_title*
		 * ```
		 */
		@ $mol_mem_key
		Post(id: any) {
			const obj = new this.$.$mol_link()
			
			obj.uri = () => this.post_url(id)
			obj.title = () => this.post_title(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * PostView*0 $mol_page
		 * 	title <= post_title*
		 * 	body / <= text*
		 * ```
		 */
		@ $mol_mem_key
		PostView(id: any) {
			const obj = new this.$.$mol_page()
			
			obj.title = () => this.post_title(id)
			obj.body = () => [
				this.text(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Foot \@powered by koplenov
		 * ```
		 */
		Foot() {
			return "@powered by koplenov"
		}
		
		/**
		 * ```tree
		 * Themme $mol_theme_auto
		 * ```
		 */
		@ $mol_mem
		Themme() {
			const obj = new this.$.$mol_theme_auto()
			
			return obj
		}
		
		/**
		 * ```tree
		 * seo_description_content? \Блог koplenov! Немного о жизни и так, о насущном..
		 * ```
		 */
		@ $mol_mem
		seo_description_content(next?: any) {
			if ( next !== undefined ) return next as never
			return "Блог koplenov! Немного о жизни и так, о насущном.."
		}
		
		/**
		 * ```tree
		 * seo_description $mol_seo_description content <= seo_description_content?
		 * ```
		 */
		@ $mol_mem
		seo_description() {
			const obj = new this.$.$mol_seo_description()
			
			obj.content = () => this.seo_description_content()
			
			return obj
		}
		
		/**
		 * ```tree
		 * seo_robots $mol_seo_robots content \index, follow
		 * ```
		 */
		@ $mol_mem
		seo_robots() {
			const obj = new this.$.$mol_seo_robots()
			
			obj.content = () => "index, follow"
			
			return obj
		}
		
		/**
		 * ```tree
		 * main_page $mol_link
		 * 	hint \На главную
		 * 	title \家
		 * 	uri \#
		 * ```
		 */
		@ $mol_mem
		main_page() {
			const obj = new this.$.$mol_link()
			
			obj.hint = () => "На главную"
			obj.title = () => "家"
			obj.uri = () => "#"
			
			return obj
		}
		
		/**
		 * ```tree
		 * query? \
		 * ```
		 */
		@ $mol_mem
		query(next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * search $mol_search query? <=> query?
		 * ```
		 */
		@ $mol_mem
		search() {
			const obj = new this.$.$mol_search()
			
			obj.query = (next?: any) => this.query(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Lighter $mol_lights_toggle
		 * ```
		 */
		@ $mol_mem
		Lighter() {
			const obj = new this.$.$mol_lights_toggle()
			
			return obj
		}
		
		/**
		 * ```tree
		 * head_title $mol_view sub /
		 * 	<= seo_description
		 * 	<= seo_robots
		 * 	<= main_page
		 * 	<= search
		 * 	<= Lighter
		 * ```
		 */
		@ $mol_mem
		head_title() {
			const obj = new this.$.$mol_view()
			
			obj.sub = () => [
				this.seo_description(),
				this.seo_robots(),
				this.main_page(),
				this.search(),
				this.Lighter()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * post_url* \#id=1
		 * ```
		 */
		post_url(id: any) {
			return "#id=1"
		}
		
		/**
		 * ```tree
		 * post_title* \post title
		 * ```
		 */
		post_title(id: any) {
			return "post title"
		}
		
		/**
		 * ```tree
		 * post_content* \blank content
		 * ```
		 */
		post_content(id: any) {
			return "blank content"
		}
		
		/**
		 * ```tree
		 * text* $mol_text
		 * 	text <= post_content*
		 * 	highlight <= query
		 * ```
		 */
		@ $mol_mem_key
		text(id: any) {
			const obj = new this.$.$mol_text()
			
			obj.text = () => this.post_content(id)
			obj.highlight = () => this.query()
			
			return obj
		}
	}
	
}

